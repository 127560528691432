.me-orange, .me-orange a {
  color: #E55123;
  text-decoration: none; }

.me-black, .me-black a {
  color: black;
  text-decoration: none; }

.line-height-1 {
  line-height: 1; }

.me-list-style {
  list-style: none;
  color: black;
  padding: 0;
  margin: 0;

  li {
    padding-left: 25px;
    text-indent: -14px;
    padding-bottom: 7px; }

  li::before {
    content: "■";
    color: $primary-color;
    padding-right: 5px; } }


.me-table-style {
  font-size: 0.8em;
  width: 100%;
  border-spacing: 1px;
  border-collapse: separate;

  td, th {
    padding: 8px;
    &:nth-child(odd) {
      background-color: #FFBDA8; }
    &:nth-child(even) {
      background-color: #FFD2C4; } } }
