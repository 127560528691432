.logo-area {

  .logo-link {
    text-decoration: none; }

  .logo-text {
    color: #666;
    font-size: 1.3em; }

  .logo-img {
    vertical-align: middle; }

  @media only screen and (max-width : 800px) {
    .logo-text {
      font-size: 1em; }

    .logo-img {
      width: 75px;
      height: auto; } } }
