
.card-columns-plugin {

  .card-columns {
    column-break-inside: avoid; }

  .card {
    border: none;
    border-radius: 0; }


  .card-columns-sm-1 {
    @include media-breakpoint-up(sm) {
      column-count: 1; } }

  .card-columns-sm-2 {
    @include media-breakpoint-up(sm) {
      column-count: 2; } }

  .card-columns-md-1 {
    @include media-breakpoint-up(md) {
      column-count: 1; } }

  .card-columns-md-2 {
    @include media-breakpoint-up(md) {
      column-count: 2; } }

  .card-columns-md-3 {
    @include media-breakpoint-up(md) {
      column-count: 3; } }

  .card-columns-lg-1 {
    @include media-breakpoint-up(lg) {
      column-count: 1; } }

  .card-columns-lg-2 {
    @include media-breakpoint-up(lg) {
      column-count: 2; } }

  .card-columns-lg-3 {
    @include media-breakpoint-up(lg) {
      column-count: 3; } }

  .card-columns-lg-4 {
    @include media-breakpoint-up(lg) {
      column-count: 4; } }

  .card-columns-xl-1 {
    @include media-breakpoint-up(xl) {
      column-count: 1; } }

  .card-columns-xl-2 {
    @include media-breakpoint-up(xl) {
      column-count: 2; } }

  .card-columns-xl-3 {
    @include media-breakpoint-up(xl) {
      column-count: 3; } }

  .card-columns-xl-4 {
    @include media-breakpoint-up(xl) {
      column-count: 4; } }

  .card-columns-xl-5 {
    @include media-breakpoint-up(xl) {
      column-count: 5; } } }
