.main-slideshow-container {
  margin-bottom: 10px;
  .items {
    position: relative;
    padding-top: 100%;
    .main-slideshow-item {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      .image {
        max-width: 100%;
        height: auto;
        width: 100%; }
      .caption {
        position: absolute;
        display: inline-block;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: black;
        color: white;
        opacity: 0.5;
        filter: alpha(opacity=50);
        padding: 0;
        width: 100%;
        span {
          margin: 4px;
          margin-left: 10px;
          display: block; } } } }

  .controls {
    text-align: right;
    font-size: 0.8em;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    a {
      color: black; } } }
