// bootstrap is loaded in a different bundle, so we have to import whats needed here again.
@import "../libraries/bootstrap4";
@import "~bootstrap/scss/bootstrap-grid.scss";
@import "variables";

body {
  font-family: 'Open Sans', sans-serif; }


h1 {
  color: $primary-color;
  font-size: 1.5em; }

h2 {
  color: $primary-color;
  font-size: 1.2em; }

h3 {
  color: $primary-color;
  font-size: 1.05em; }

h4 {
  color: $primary-color;
  font-size: 1em;
  font-weight: bold; }

.main-content {
  border-top: 1px solid $secondary-color;
  padding-top: 30px;
  @include media-breakpoint-up(md) {
    padding: 30px;
    border-left: 1px solid $secondary-color; } }

footer {
  margin-top: 20px;
  p {
    line-height: normal;
    margin-bottom: 0.4em; } }


table {
  width: 100%;
  padding: 0.5 * $spacer; }

