

.testimonials-app {
  .testimonials-detail {} }


.testimonial-teaser-plugin {}


.testimonials-popover {
  max-width: 250px; }
