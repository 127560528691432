
.main-menu {
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 1em;
  font-weight: bold;

  a {
    color: $primary-font-color;
    font-weight: bold; }

  .submenu {
    display: none; }

  .menu-item {
    display: inline-block;
    width: auto;
    white-space: nowrap;
    line-height: normal; } }


.popover {
  .submenu {
    list-style: none;
    color: black;
    a {
      color: $primary-color;
      font-weight: 500;
      font-size: 1.1em;
      line-height: normal; } }
  font-family: "Open Sans", sans-serif;
  max-width: 95vw;
  min-width: 100px;
  border-radius: 0;
  border : 1px solid silver;

  ul {
    list-style: none;
    padding: 0;
    margin: 0; }

  .menu-item {
    padding-top: 3px;
    padding-bottom: 3px; }

  @media only screen and (max-width : 600px) {
      height: auto;
      max-width: 250px; } }


